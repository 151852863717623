import { Link } from '@mui/material';
import { ReactComponent as PlayStoreIcon } from '../../images/PlayStoreLogo.svg';

const TradeLegionPlayStoreUrl =
	'https://play.google.com/store/apps/details?id=nz.co.tradelegion.tradelegion.android';

export type PlayStoreLogoProps = {
	height: React.SVGProps<SVGSVGElement>['height'];
};

export const PlayStoreLogo = ({ height }: PlayStoreLogoProps): JSX.Element => (
	<Link href={TradeLegionPlayStoreUrl}>
		<PlayStoreIcon height={height} />
	</Link>
);

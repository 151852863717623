import { Box } from '@mui/material';
import { Note } from '../../../../../constants/Note';
import { InputNote } from '../../Details/EditTimesheet/Notes/InputNote';

export type NotesCellProps = {
	disabled: boolean;
	note: Pick<Note, 'note' | 'user'>;
	updateNote: (note: string) => void;
};

export const NotesCell = ({
	disabled,
	note,
	updateNote,
}: NotesCellProps): JSX.Element => {
	return (
		<Box>
			<InputNote
				note={note}
				onNoteChange={updateNote}
				disabled={disabled}
				headerAsLabel
			/>
		</Box>
	);
};

import { UserProps } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { useAccountsContext } from './AccountsWrapper';
import { Users } from './Users';

export const UsersWrapper = ({ userDetails }: UserProps): JSX.Element => {
	const { context } = useAccountsContext();

	return (
		<Users
			userDetails={userDetails}
			users={context.users}
			sites={context.sites}
			companies={context.companies}
			loading={context.loadingUsers}
			firebaseApi={firebaseApi}
		/>
	);
};

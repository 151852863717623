import { Box } from '@mui/material';
import { IncrementedTimeSelect } from '../../Details/EditTimesheet/IncrementedTimeSelect';

export type BreaksCellProps = {
	breakHours: number;
	disabled: boolean;
	updateBreaks: (breakHours: number) => void;
};

export const BreaksCell = ({
	breakHours,
	updateBreaks,
	disabled,
}: BreaksCellProps): JSX.Element => {
	return (
		<Box
			display="flex"
			justifyContent={{
				xs: 'flex-end',
				sm: 'flex-end',
				md: 'center',
				lg: 'center',
			}}>
			<IncrementedTimeSelect
				disabled={disabled}
				removeInputPadding
				maxHours={4}
				hours={breakHours}
				setHours={(hours): void => updateBreaks(hours)}
			/>
		</Box>
	);
};

import { Link } from '@mui/material';
import { ReactComponent as AppStoreIcon } from '../../images/AppStoreLogo-altered.svg';

const TradeLegionAppStoreUrl =
	'https://apps.apple.com/nz/app/trade-legion/id1570424533';

export type AppStoreLogoProps = {
	height: React.SVGProps<SVGSVGElement>['height'];
};

export const AppStoreLogo = ({ height }: AppStoreLogoProps): JSX.Element => (
	<Link href={TradeLegionAppStoreUrl}>
		<AppStoreIcon height={height} />
	</Link>
);

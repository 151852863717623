import {
	MUIDataTableColumnDef,
	MUIDataTableMeta,
	MUIDataTableOptions,
} from 'mui-datatables';
import { ReactNode } from 'react';
import { ExplicitAny } from './AnyTypes';

export type InternalData = { index: number; data: ExplicitAny[] }[];
type InternalHeader = { name: string; label: string };

const filterColumns = <T>(array: T[], excludedColumns?: number[]): T[] =>
	excludedColumns
		? array.filter((_, index) => !excludedColumns.includes(index))
		: array;

type Options = {
	excludedColumns?: number[];
	overriddenColumns?: {
		displayedLabel: string;
		overrideLabel?: string;
		render: (input: ExplicitAny) => string | number;
	}[];
};

const getDisplayedLabel = (columnDef: MUIDataTableColumnDef): string =>
	typeof columnDef === 'string'
		? columnDef
		: 'label' in columnDef && columnDef.label !== undefined
		? columnDef.label
		: columnDef.name;

/** Return a list of duplicate column labels (and the number of times they occur) which may cause issues for overrides */
const getDuplicateColumnLabels = (
	columnDefs: MUIDataTableColumnDef[],
): [string, number][] =>
	Object.entries(
		columnDefs.map(getDisplayedLabel).reduce((acc, label) => {
			if (!(label in acc)) {
				acc[label] = 0;
			}
			acc[label] += 1;
			return acc;
		}, {} as Record<string, number>),
	).filter(([_, occurences]) => occurences > 1);

/** Create a callback for downloading CSVs in the same (or similar) format as they are presented in MUI tables
 * @param columnDefs - The Column definitions used for the table (will likely include `customBodyRender` or `customBodyRenderLite` functions)
 * @param options.excludedColumns - The index of columns to be excluded from the csv - omitting this parameter will mean all columns are exported
 * @param options.overridenColumns - Provide a custom rendering of the cell, if neither the raw data or the default rendering are appropriate. Override matches based upon the label for the column displayed to the user on the table */
export const onDownload: (
	columnDefs: MUIDataTableColumnDef[],
	options?: Options,
) => NonNullable<MUIDataTableOptions['onDownload']> =
	(columnDefs: MUIDataTableColumnDef[], options?: Options) =>
	(buildHead, buildBody, headers: InternalHeader[], data: InternalData) => {
		if (options?.overriddenColumns) {
			const dupes = getDuplicateColumnLabels(columnDefs);
			if (dupes.length > 0) {
				console.error(
					'Some columns have duplicate labels, which may cause issues when overriding columns in csv exports',
					dupes,
				);
			}
		}

		const includedHeaders = filterColumns(
			headers,
			options?.excludedColumns,
		);

		const mappedHeaders = options?.overriddenColumns // check if there are no overrides so we can skip checking each header individually
			? includedHeaders.map((header) => {
					const override = options?.overriddenColumns?.find(
						(override) =>
							override.displayedLabel ===
							getDisplayedLabel(header),
					);
					return override?.overrideLabel
						? { ...header, label: override.overrideLabel }
						: header;
			  })
			: includedHeaders;

		const includedData = data.map((col) => ({
			...col,
			data: filterColumns(col.data, options?.excludedColumns),
		}));

		const mappedData = includedData.map((col) => ({
			index: col.index,
			data: col.data.map((cell, index) => {
				const columnDef = columnDefs[index];

				const override = options?.overriddenColumns?.find(
					(override) =>
						getDisplayedLabel(columnDef) ===
						override.displayedLabel,
				);
				if (override) return override.render(cell);

				if (typeof columnDef === 'string') {
					return cell;
				}

				const columnOptions = columnDef.options;
				// Get the representation of the cell, as seen in the actual table
				let rendered: string | number | ReactNode;
				if (
					!columnOptions?.customBodyRender &&
					!columnOptions?.customBodyRenderLite
				) {
					// mui will render the cell value as-is
					rendered = cell;
				} else if (columnOptions.customBodyRender) {
					rendered = columnOptions.customBodyRender(
						cell,
						{} as MUIDataTableMeta,
						() => undefined,
					);
				} else if (columnOptions.customBodyRenderLite) {
					rendered = columnOptions.customBodyRenderLite(
						col.index,
						index,
					);
				}

				// check whether we should use the render or the raw data
				if (
					typeof rendered === 'string' ||
					typeof rendered === 'number'
				) {
					return rendered;
				} else if (
					typeof rendered !== 'string' &&
					(typeof cell === 'string' || typeof cell === 'number')
				) {
					// if we rendered  a node, then get the raw string interpretation instead
					return cell;
				} else {
					console.warn(
						'Neither the cell nor the rendering of that cell is easily representable as a number or a string',
						cell,
						rendered,
					);
					return JSON.stringify(cell);
				}
			}),
		}));

		return '\uFEFF' + buildHead(mappedHeaders) + buildBody(mappedData);
	};

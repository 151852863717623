import {
	Grid,
	GridProps,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { TimesheetSectionNames } from './TimesheetSectionNames';

// To align SiteLog hours text with total hours
const ICON_BUTTON_WIDTH = '36px';
const ROW_MIN_WIDTH = 320;

export type CreateRowProps = {
	day: JSX.Element;
	sitelog: JSX.Element;
	timesheetHours: JSX.Element;
	breaks: JSX.Element;
	notes: JSX.Element;
	customContainerProps?: GridProps & { 'data-testid'?: string };
};

/**
 * Manages the layout of rows in the create timesheet form.
 * Takes in elements to render for each column
 */
export const CreateRow = ({
	day,
	sitelog,
	timesheetHours,
	breaks,
	notes,
	customContainerProps,
}: CreateRowProps): JSX.Element => {
	const theme = useTheme();
	const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Grid
			container
			px={2}
			columnSpacing={1}
			minWidth={ROW_MIN_WIDTH}
			{...customContainerProps}>
			<Grid
				item
				xs={12}
				sm={12}
				md={1}
				sx={{ fontWeight: isSmallScreen ? 'bold' : undefined }}>
				{day}
			</Grid>
			{isSmallScreen && (
				<Grid item xs={6} sm={6}>
					<Typography>
						{TimesheetSectionNames.SITE_LOG_HOURS}
					</Typography>
				</Grid>
			)}
			<Grid
				item
				xs={6}
				sm={6}
				md={2}
				paddingRight={isSmallScreen ? ICON_BUTTON_WIDTH : undefined}>
				{sitelog}
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={5}
				data-testid={`${customContainerProps?.['data-testid']}-hours`}>
				{timesheetHours}
			</Grid>
			{isSmallScreen && (
				<Grid item xs={8} sm={8} paddingTop={1}>
					<Typography>
						{TimesheetSectionNames.UNPAID_BREAKS}
					</Typography>
				</Grid>
			)}
			<Grid
				item
				xs={4}
				sm={4}
				md={2}
				paddingRight={isSmallScreen ? ICON_BUTTON_WIDTH : undefined}
				paddingTop={isSmallScreen ? 1 : 0.5}
				data-testid={`${customContainerProps?.['data-testid']}-breaks`}>
				{breaks}
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={2}
				paddingRight={isSmallScreen ? ICON_BUTTON_WIDTH : undefined}
				paddingTop={isSmallScreen ? 1 : 0.5}>
				{notes}
			</Grid>
		</Grid>
	);
};

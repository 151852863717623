import { createTheme, Theme, ThemeProvider } from '@mui/material';

export const AccountsTableTheme = ({
	children,
	centeredColumns,
}: {
	children: JSX.Element;
	centeredColumns?: number[];
}): JSX.Element => {
	const columns = centeredColumns
		?.map((col) => `&:nth-of-type(${col})`)
		.join(', ');

	return (
		<ThemeProvider
			theme={(baseTheme: Theme): Theme =>
				createTheme({
					...baseTheme,
					components: {
						...baseTheme.components,
						MuiTableCell: {
							styleOverrides: {
								root: {
									padding: '8px 26px',
								},
							},
						},
						MUIDataTableHeadCell: {
							styleOverrides: {
								root:
									columns !== undefined
										? {
												[columns]: {
													'& span': {
														display: 'flex',
														justifyContent:
															'center',
														margin: 0,
													},
													'& button': {
														margin: 0,
													},
													'& div': {
														display: 'flex',
														justifyContent:
															'center',
														margin: 0,
													},
												},
										  }
										: {},
							},
						},
					},
				})
			}>
			{children}
		</ThemeProvider>
	);
};

import { PropsOf } from '@emotion/react';
import {
	Autocomplete,
	MenuItem,
	Stack,
	SxProps,
	TextField,
	Theme,
	Typography,
} from '@mui/material';
import { hoursOptions } from '../timesheetTableUtilities';

type IncrementedTimeSelectProps = {
	hours: number;
	setHours: (hours: number) => void;
	maxHours: number;
	minuteIncrement?: 3 | 6 | 12 | 15 | 30;

	InputProps?: PropsOf<typeof TextField>;
	disabled?: boolean;
	removeInputPadding?: boolean;
	error?: boolean;
};
export const IncrementedTimeSelect = ({
	hours,
	setHours,
	maxHours,
	minuteIncrement = 15,
	InputProps = {},
	disabled,
	removeInputPadding,
	error,
}: IncrementedTimeSelectProps): JSX.Element => {
	const textFieldSx: SxProps<Theme> = removeInputPadding
		? {
				px: 1,
				py: 0.5,
				height: '14px', // autocomplete not recognizing size='small'?
		  }
		: {};

	const options = hoursOptions(maxHours, minuteIncrement);

	return (
		<Autocomplete
			disableClearable
			sx={{ minWidth: '130px', paddingLeft: 1 }}
			disabled={disabled}
			options={Object.keys(options)
				.map(parseFloat)
				.sort((hours1, hours2): number => (hours1 > hours2 ? 1 : -1))}
			onChange={(_, option): void => {
				setHours(option);
			}}
			renderInput={(props): JSX.Element => (
				<TextField
					{...props}
					{...InputProps}
					size="small"
					label={
						<Stack direction="row">
							<Typography>Hours</Typography>
							<Typography color="error.main">*</Typography>
						</Stack>
					}
					error={error}
					inputProps={{
						...props.inputProps,
						// want the field to be as small as possible without label overflow
						sx: {
							...textFieldSx,
						},
					}}
				/>
			)}
			value={hours}
			size="small"
			getOptionLabel={(option): string => options[option]}
			renderOption={(props, option): JSX.Element => (
				<MenuItem {...props} key={option} value={option}>
					{options[option]}
				</MenuItem>
			)}
		/>
	);
};

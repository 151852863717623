import { Box, Tab, Tabs } from '@mui/material';
import { useEffect } from 'react';
import { useMatch, useSearchParams, Link } from 'react-router-dom';
import {
	AccountsAccountType,
	AccountsConfig,
	accountApprovalsMap,
	accountsAccountType,
} from '../../constants/Accounts';
import {
	Company,
	CompanyTypes,
	UserDetails,
	UserProps,
	accountTypes,
	assertAccountType,
} from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { AccountsState } from './StateManagement/actions';

export type AccountsProps = UserProps & {
	setUserCompany: (company: Company) => void;
	setSites: (sites: AccountsState['sites']) => void;
	setCompanySites: (companySites: AccountsState['sites']) => void;
	setCompanies: (companies: AccountsState['companies']) => void;
	setUsers: (users: AccountsState['users']) => void;
	setUnapprovedUsers: (
		unapprovedUsers: AccountsState['unapprovedUsers'],
	) => void;
	setLoadingNewAccounts: (
		loadingNewAccountsState: AccountsState['loadingNewAccounts'],
	) => void;
	setLoadingUsers: (loadingUsersState: AccountsState['loadingUsers']) => void;
	firebaseApi: Pick<
		FirebaseApi,
		| 'activeSitesByCompanySubscription'
		| 'companiesSubscriptionByType'
		| 'activeSitesByCompanyTypeSubscription'
		| 'subscribeUnapprovedUsersByCompany'
		| 'subscribeNonKioskUsersByCompany'
		| 'getCompany'
	>;
};

export const Accounts = ({
	userDetails,
	setUserCompany,
	setSites,
	setCompanySites,
	setCompanies,
	setUnapprovedUsers,
	setUsers,
	setLoadingNewAccounts,
	setLoadingUsers,
	firebaseApi,
}: AccountsProps): JSX.Element => {
	const accountType = userDetails.accountType;
	assertAccountType<AccountsAccountType>(accountType, accountsAccountType);
	const titles = AccountsConfig[accountType].titles;
	const showContractedTo = accountType === accountTypes.handler;
	const pathMatch = useMatch('/accounts/:tab');
	const [searchParams] = useSearchParams();

	useEffect(() => {
		const getCompany = async (): Promise<void> => {
			const company = await firebaseApi.getCompany(userDetails.companyID);
			if (company) setUserCompany(company);
		};
		getCompany();
	}, [
		firebaseApi,
		setUserCompany,
		userDetails.accountType,
		userDetails.companyID,
	]);

	useEffect(() => {
		const companiesSubscription = firebaseApi.companiesSubscriptionByType(
			CompanyTypes.construction,
			(companiesList) => {
				const companiesMap = companiesList.reduce(
					(companyRecords, company) => {
						companyRecords[company.id] = company;
						return companyRecords;
					},
					{} as Record<string, Company>,
				);
				setCompanies(companiesMap);
			},
		);

		if (!showContractedTo) {
			return firebaseApi.activeSitesByCompanySubscription(
				userDetails.companyID,
				(siteMap) => {
					setSites(siteMap);
				},
			);
		} else {
			const constructionSites =
				firebaseApi.activeSitesByCompanyTypeSubscription(
					CompanyTypes.construction,
					(siteMap) => setSites(siteMap),
				);
			const companySitesSubscription =
				firebaseApi.activeSitesByCompanySubscription(
					userDetails.companyID,
					(siteMap) => setCompanySites(siteMap),
				);
			return (): void => {
				constructionSites();
				companySitesSubscription();
				companiesSubscription();
			};
		}
	}, [
		firebaseApi,
		setCompanies,
		setCompanySites,
		setSites,
		showContractedTo,
		userDetails.accountType,
		userDetails.companyID,
	]);

	useEffect(() => {
		return firebaseApi.subscribeUnapprovedUsersByCompany(
			userDetails.companyID,
			(unapprovedUsers: Record<string, UserDetails>): void => {
				setUnapprovedUsers(unapprovedUsers);
				setLoadingNewAccounts(false);
			},
		);
	}, [
		firebaseApi,
		setLoadingNewAccounts,
		setUnapprovedUsers,
		userDetails.accountType,
		userDetails.companyID,
	]);

	useEffect(() => {
		return firebaseApi.subscribeNonKioskUsersByCompany(
			userDetails.companyID,
			(newUsers: UserDetails[]): void => {
				const filteredUsers = newUsers.filter((user) =>
					accountApprovalsMap[accountType].includes(user.accountType),
				);
				const users = filteredUsers.reduce((acc, user) => {
					acc[user.userID] = user;
					return acc;
				}, {} as Record<string, UserDetails>);
				setUsers(users);
				setLoadingUsers(false);
			},
		);
	}, [accountType, firebaseApi, setLoadingUsers, setUsers, userDetails]);

	return (
		<Box
			sx={{
				borderBottom: 1,
				borderColor: 'divider',
			}}>
			<Tabs
				variant="scrollable"
				scrollButtons="auto"
				value={pathMatch?.params?.tab?.replace(/-/g, ' ')}>
				{titles.map((title, index) => (
					<Tab
						label={title}
						value={title}
						key={index}
						component={Link}
						to={`${title.replace(/\s+/g, '-')}?${searchParams}`}
					/>
				))}
			</Tabs>
		</Box>
	);
};

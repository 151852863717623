import { AccountType, UserDetails } from './Common';

export const accountsAccountType = [
	'handler',
	'management',
	'seniorManagement',
] as const;

export type AccountsAccountType = AccountType &
	(typeof accountsAccountType)[number];

export type AccountsConfig = {
	titles: string[];
};

export const AccountsConfig: Record<AccountsAccountType, AccountsConfig> = {
	management: {
		titles: ['new accounts', 'users'],
	},
	handler: {
		titles: ['new accounts', 'users'],
	},
	seniorManagement: {
		titles: ['new accounts', 'users'],
	},
};

export const accountApprovalsMap: Record<AccountsAccountType, AccountType[]> = {
	handler: ['thirdPartyWorker', 'handler'],
	management: ['management', 'worker'],
	seniorManagement: ['seniorManagement', 'management', 'worker'],
};

export type UserOptionsAccountUpdateFields = Pick<
	UserDetails,
	| 'site'
	| 'siteID'
	| 'siteCompany'
	| 'siteCompanyID'
	| 'contractedTo'
	| 'accountType'
	| 'disabledLeave'
	| 'disableTimesheetApproval'
>;

import { useCallback, useReducer } from 'react';
import { Outlet, useOutletContext } from 'react-router';
import { UserProps } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { Accounts } from './Accounts';
import {
	AccountsOutletContext,
	AccountsState,
	setCompanies,
	setCompanySites,
	setLoadingNewAccounts,
	setLoadingUsers,
	setSites,
	setUnapprovedUsers,
	setUserCompany,
	setUsers,
} from './StateManagement/actions';
import {
	accountsReducer,
	createInitialAccountsState,
} from './StateManagement/reducer';

export const AccountsWrapper = ({ userDetails }: UserProps): JSX.Element => {
	const [context, dispatch] = useReducer(
		accountsReducer,
		{},
		createInitialAccountsState,
	);

	return (
		<>
			<Accounts
				userDetails={userDetails}
				setUserCompany={useCallback(
					(company: AccountsState['userCompany']): void =>
						dispatch(setUserCompany(company)),
					[],
				)}
				setSites={useCallback(
					(sites: AccountsState['sites']): void =>
						dispatch(setSites(sites)),
					[],
				)}
				setCompanySites={useCallback(
					(companySites: AccountsState['companySites']): void =>
						dispatch(setCompanySites(companySites)),
					[],
				)}
				setCompanies={useCallback(
					(companies: AccountsState['companies']): void =>
						dispatch(setCompanies(companies)),
					[],
				)}
				setUsers={useCallback(
					(users: AccountsState['users']): void =>
						dispatch(setUsers(users)),
					[],
				)}
				setUnapprovedUsers={useCallback(
					(unapprovedUsers: AccountsState['unapprovedUsers']): void =>
						dispatch(setUnapprovedUsers(unapprovedUsers)),
					[],
				)}
				setLoadingUsers={useCallback(
					(loadingUsersState: AccountsState['loadingUsers']): void =>
						dispatch(setLoadingUsers(loadingUsersState)),
					[],
				)}
				setLoadingNewAccounts={useCallback(
					(
						loadingNewAccountsState: AccountsState['loadingNewAccounts'],
					): void =>
						dispatch(
							setLoadingNewAccounts(loadingNewAccountsState),
						),
					[],
				)}
				firebaseApi={firebaseApi}
			/>
			<Outlet context={{ context, dispatch }} />
		</>
	);
};

/**
 * Gets the reducer state and dispatch function required by children of Accounts
 */
export const useAccountsContext = (): AccountsOutletContext =>
	useOutletContext<AccountsOutletContext>();

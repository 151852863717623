import { endOfWeek, subWeeks } from 'date-fns';
import { cloneDeep } from 'lodash';
import {
	accountTypes,
	Activity,
	Company,
	CompanyTypes,
	DayString,
	Site,
	SiteLog,
	UserDetails,
} from '../../../../constants/Common';
import { Note } from '../../../../constants/Note';
import { Timesheet } from '../../../../constants/Timesheet/Timesheet';
import { TimesheetStatus } from '../../../../constants/Timesheet/TimesheetStatus';
import {
	NewTimesheetStatus,
	validTimesheetDateRange,
} from '../../../../constants/Timesheet/TimesheetUtilities';
import { getUuid } from '../../../helpers/uuidHelpers';
import { CreateActions, CreateActionTypes } from './actions';
import { HeaderFields } from './HeaderFields';

export type NewActivity = Pick<Activity, 'id' | 'day' | 'hours'> & {
	activity: Activity['activity'] | null; // null temp new
};

export type CreateState = {
	creator: Timesheet['lastEditedBy'];
	duplicateTimesheet: null;
	initialLoad: boolean;
	siteLogs: SiteLog[];
	headerErrors: Record<HeaderFields, boolean>;
	selectedWorker: Pick<
		UserDetails,
		| 'userID'
		| 'company'
		| 'companyID'
		| 'contractedTo'
		| 'displayName'
		| 'recentClients'
	> | null;
	selectedClient: Pick<Company, 'id' | 'name'> | null;
	selectedSite: Site | null;
	selectedTimesheetStatus: NewTimesheetStatus;
	selectedWeekEnding: Date;
	activities: Record<DayString, Record<Activity['id'], NewActivity>>;
	breaks: Record<DayString, number>;
	notes: Record<DayString, Pick<Note, 'note' | 'user'>>;
	authorizedActions: Record<
		| 'canChangeClient'
		| 'canChangeSite'
		| 'canChangeStatus'
		| 'isRecruitmentSite',
		boolean
	>;
};

export const createInitialCreateState = (initArg: {
	userDetails: Pick<
		UserDetails,
		| 'userID'
		| 'displayName'
		| 'accountType'
		| 'contractedTo'
		| 'siteID'
		| 'disableTimesheetApproval'
	>;
	sites: Record<string, Site>;
	weekEnding: Date;
	loading: boolean;
}): CreateState => {
	const initialSite = initArg.sites[initArg.userDetails.siteID] ?? null;

	const canChangeClient =
		initArg.userDetails.accountType === accountTypes.handler;
	const canChangeSite =
		initArg.userDetails.accountType !== accountTypes.management;
	const canChangeStatus =
		initArg.userDetails.accountType === accountTypes.seniorManagement ||
		(initArg.userDetails.accountType === accountTypes.management &&
			!initArg.userDetails.disableTimesheetApproval);
	const isRecruitmentSite =
		initialSite && initialSite.companyType === CompanyTypes.recruitment;

	const initialClient =
		isRecruitmentSite && initialSite
			? {
					id: initialSite.companyID,
					name: initialSite.company,
			  }
			: !canChangeClient
			? initArg.userDetails.contractedTo
			: null;
	const initialTimesheetStatus = !canChangeStatus
		? TimesheetStatus.Submitted
		: TimesheetStatus.Approved;

	const dateLimits = validTimesheetDateRange(new Date());
	const initialWeekEnding =
		initArg.weekEnding >= dateLimits.minDate &&
		initArg.weekEnding <= dateLimits.maxDate
			? initArg.weekEnding
			: subWeeks(endOfWeek(new Date()), 1);

	const initialNote: Pick<Note, 'note' | 'user'> = {
		note: '',
		user: {
			id: initArg.userDetails.userID,
			name: initArg.userDetails.displayName,
		},
	};

	return {
		creator: {
			id: initArg.userDetails.userID,
			name: initArg.userDetails.displayName,
		},
		duplicateTimesheet: null,
		initialLoad: initArg.loading,
		siteLogs: [],
		headerErrors: {
			[HeaderFields.Worker]: false,
			[HeaderFields.Client]: false,
			[HeaderFields.Site]: false,
			[HeaderFields.Status]: false,
			[HeaderFields.WeekEnding]: false,
		},
		selectedWorker: null,
		selectedClient: initialClient,
		selectedSite: initialSite,
		selectedTimesheetStatus: initialTimesheetStatus,
		selectedWeekEnding: initialWeekEnding,
		activities: {
			Monday: {},
			Tuesday: {},
			Wednesday: {},
			Thursday: {},
			Friday: {},
			Saturday: {},
			Sunday: {},
		},
		breaks: {
			Monday: 0,
			Tuesday: 0,
			Wednesday: 0,
			Thursday: 0,
			Friday: 0,
			Saturday: 0,
			Sunday: 0,
		},
		notes: {
			Monday: { ...initialNote },
			Tuesday: { ...initialNote },
			Wednesday: { ...initialNote },
			Thursday: { ...initialNote },
			Friday: { ...initialNote },
			Saturday: { ...initialNote },
			Sunday: { ...initialNote },
		},
		authorizedActions: {
			canChangeClient,
			canChangeSite,
			canChangeStatus,
			isRecruitmentSite,
		},
	};
};

export const createTimesheetReducer = (
	state: CreateState,
	action: CreateActions,
): CreateState => {
	switch (action.type) {
		case CreateActionTypes.SELECT_WORKER:
			return {
				...state,
				selectedWorker: action.payload.worker,
				headerErrors: {
					...state.headerErrors,
					[HeaderFields.Worker]: false,
				},
			};
		case CreateActionTypes.SELECT_CLIENT:
			return {
				...state,
				selectedClient: action.payload.client,
				headerErrors: {
					...state.headerErrors,
					[HeaderFields.Client]: false,
				},
			};
		case CreateActionTypes.SELECT_SITE: {
			const isRecruitmentSite =
				action.payload.site?.companyType === CompanyTypes.recruitment;

			// If the site is a recruitment site, the client should be the recruitment company
			const client =
				isRecruitmentSite && action.payload.site
					? {
							id: action.payload.site.companyID,
							name: action.payload.site.company,
					  }
					: !isRecruitmentSite &&
					  state.authorizedActions.isRecruitmentSite
					? null
					: state.selectedClient;
			return {
				...state,
				selectedSite: action.payload.site,
				selectedClient: client,
				headerErrors: {
					...state.headerErrors,
					[HeaderFields.Site]: false,
				},
				activities: {
					Monday: {},
					Tuesday: {},
					Wednesday: {},
					Thursday: {},
					Friday: {},
					Saturday: {},
					Sunday: {},
				},
				authorizedActions: {
					...state.authorizedActions,
					isRecruitmentSite: isRecruitmentSite,
				},
			};
		}
		case CreateActionTypes.SELECT_TIMESHEET_STATUS:
			return {
				...state,
				selectedTimesheetStatus: action.payload.timesheetStatus,
				headerErrors: {
					...state.headerErrors,
					[HeaderFields.Status]: false,
				},
			};
		case CreateActionTypes.SELECT_WEEK_ENDING:
			return {
				...state,
				selectedWeekEnding: action.payload.weekEnding,
				headerErrors: {
					...state.headerErrors,
					[HeaderFields.WeekEnding]: false,
				},
			};
		case CreateActionTypes.UPDATE_LOADING:
			return {
				...state,
				initialLoad: action.payload.loading,
			};
		case CreateActionTypes.UPDATE_SITE_LOGS:
			return {
				...state,
				siteLogs: action.payload.siteLogs,
			};
		case CreateActionTypes.ADD_ACTIVITY: {
			const tempActivityID = `new-${getUuid()}`;
			return {
				...state,
				activities: {
					...state.activities,
					[action.payload.day]: {
						...state.activities[action.payload.day],
						[tempActivityID]: {
							id: tempActivityID,
							day: action.payload.day,
							activity: null,
							hours: 0,
						},
					},
				},
			};
		}
		case CreateActionTypes.UPDATE_ACTIVITY: {
			const updatedActivities = cloneDeep(state.activities);
			updatedActivities[action.payload.activity.day][
				action.payload.activity.id
			] = action.payload.activity;

			return {
				...state,
				activities: updatedActivities,
			};
		}
		case CreateActionTypes.DELETE_ACTIVITY: {
			const updatedActivities = cloneDeep(state.activities);
			delete updatedActivities[action.payload.day][action.payload.id];

			return {
				...state,
				activities: updatedActivities,
			};
		}
		case CreateActionTypes.UPDATE_BREAK: {
			// Breaks are limited to 4 hours
			if (
				action.payload.breakHours < 0 ||
				action.payload.breakHours > 4
			) {
				return state;
			} else {
				return {
					...state,
					breaks: {
						...state.breaks,
						[action.payload.day]: action.payload.breakHours,
					},
				};
			}
		}
		case CreateActionTypes.UPDATE_NOTE:
			return {
				...state,
				notes: {
					...state.notes,
					[action.payload.day]: {
						...state.notes[action.payload.day],
						note: action.payload.note,
					},
				},
			};

		default:
			return state;
	}
};

import { UserProps } from '../../constants/Common';
import firebaseApi from '../../firebase/firebaseApi';
import { AccountsTableTheme } from '../Documents/TableWrappers/AccountsTableWrapper';
import { useAccountsContext } from './AccountsWrapper';
import { NewAccounts } from './NewAccounts';

export const NewAccountsWrapper = ({
	userDetails,
	user,
}: UserProps): JSX.Element => {
	const { context } = useAccountsContext();

	return (
		<AccountsTableTheme centeredColumns={[3]}>
			<NewAccounts
				user={user}
				userDetails={userDetails}
				userCompany={context.userCompany}
				sites={{ ...context.sites, ...context.companySites }}
				companies={context.companies}
				loading={context.loadingNewAccounts}
				unapprovedUsers={context.unapprovedUsers}
				firebaseApi={firebaseApi}
			/>
		</AccountsTableTheme>
	);
};

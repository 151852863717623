import { Typography } from '@mui/material';
import { MUIDataTableOptions } from 'mui-datatables';
import { useState } from 'react';
import {
	AccountsAccountType,
	accountsAccountType,
} from '../../constants/Accounts';
import {
	AccountType,
	AccountTypeHumanName,
	UserDetails,
	accountTypes,
	assertAccountType,
} from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { DataTable } from '../DataTable/DataTable';
import { AccountsTableTheme } from '../Documents/TableWrappers/AccountsTableWrapper';
import { contractedToCustomTableSort } from '../helpers/muiDataTableCustomSorts';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { AccountsState } from './StateManagement/actions';
import { UsersOptionsDialog } from './UserOptionsDialog/UsersOptionsDialog';
import { UsersOptions } from './UsersOptions';

export type UsersProps = {
	userDetails: UserDetails;
	users: AccountsState['users'];
	sites: AccountsState['sites'];
	companies: AccountsState['companies'];
	loading: AccountsState['loadingUsers'];
	firebaseApi: Pick<
		FirebaseApi,
		| 'updateUserAccountDetails'
		| 'updateUserProfileCompany'
		| 'updateUserDetailsCompanyInfo'
	>;
};

export const Users = ({
	userDetails,
	users,
	sites,
	companies,
	loading,
	firebaseApi,
}: UsersProps): JSX.Element => {
	const accountType = userDetails.accountType;
	assertAccountType<AccountsAccountType>(accountType, accountsAccountType);

	const [selectedUserID, setSelectedUserID] = useState<string>('');
	const [modalOpen, setModalOpen] = useState(false);

	const selectedUser = users[selectedUserID];

	const canManageAllUsersInCompany =
		accountType === accountTypes.handler ||
		accountType === accountTypes.seniorManagement;
	const showContractedTo = accountType === accountTypes.handler;
	const noMatchTableText = loading ? (
		<LoadingDots />
	) : (
		<Typography>
			{`There are no users for your ${
				canManageAllUsersInCompany ? 'company' : 'site'
			}`}
		</Typography>
	);
	const numCells = 5;
	const cellWidth = { width: `${100 / numCells}%` };
	const setCellHeaderProps = (): {
		style: {
			width: string;
		};
	} => ({
		style: { ...cellWidth },
	});

	const columns = [
		{
			name: 'displayName',
			label: 'Name',
			options: {
				setCellHeaderProps,
			},
		},
		{
			name: 'accountType',
			label: 'Account Type',
			options: {
				setCellHeaderProps,
				customBodyRender: (accountType: AccountType): JSX.Element => (
					<>{AccountTypeHumanName[accountType]}</>
				),
			},
		},
		{
			name: 'site',
			label: 'Site',
			options: {
				setCellHeaderProps,
			},
		},
		{
			name: showContractedTo ? 'contractedTo' : 'company',
			label: showContractedTo ? 'Client' : 'Company',
			options: {
				setCellHeaderProps,
				customBodyRender: (
					company:
						| UserDetails['contractedTo']
						| UserDetails['company'],
				) => (typeof company === 'string' ? company : company?.name),
				sortCompare: contractedToCustomTableSort,
			},
		},
		{
			name: 'userID',
			label: 'Options',
			options: {
				sort: false,
				filter: false,
				searchable: false,
				setCellHeaderProps,
				customBodyRender: (userID: string): JSX.Element =>
					users[userID] && (
						<UsersOptions
							modalOpen={modalOpen && selectedUserID === userID}
							setModalOpen={setModalOpen}
							setSelected={(userID: string): void =>
								setSelectedUserID(userID)
							}
							accountType={accountType}
							existingUser={users[userID]}
							canRemoveUser={
								canManageAllUsersInCompany &&
								userID !== userDetails.userID &&
								users[userID].companyID ===
									userDetails.companyID
							}
							sites={sites}
							firebaseApi={firebaseApi}
						/>
					),
			},
		},
	];

	const tableOptions: MUIDataTableOptions = {
		download: false,
		print: false,
		elevation: 1,
		tableBodyHeight: 'calc(100vh - 302px)',
		viewColumns: false,
		selectToolbarPlacement: 'none',
		textLabels: {
			body: {
				noMatch: noMatchTableText,
			},
		},
	};

	const handleCloseDialog = (): void => {
		setModalOpen(false);
		setSelectedUserID('');
	};

	return (
		<>
			<AccountsTableTheme centeredColumns={[5]}>
				<DataTable
					tableData={Object.values(users)}
					columns={columns}
					title={`${userDetails.company} Users`}
					customTableOptions={tableOptions}
				/>
			</AccountsTableTheme>
			{selectedUser && (
				<UsersOptionsDialog
					modalOpen={modalOpen}
					handleCloseDialog={handleCloseDialog}
					accountsUser={{
						isCurrentSelected:
							selectedUserID === userDetails.userID,
						companyID: userDetails.companyID,
						accountType: accountType,
					}}
					selectedUser={selectedUser}
					companies={companies}
					sites={sites}
					firebaseApi={firebaseApi}
				/>
			)}
		</>
	);
};

import { Activity } from '../../constants/Common';

export type TotalActivityHours = { id: string; name: string; total: number };

type TotalActivityHoursMap = {
	totals: Record<string, TotalActivityHours>;
	allHours: number;
};

export const getActivityHourTotals = (
	activities: Pick<Activity, 'id' | 'activity' | 'hours'>[],
): TotalActivityHoursMap => {
	let allHours = 0;
	const totals = activities.reduce<TotalActivityHoursMap['totals']>(
		(totals, activity) => {
			if (totals[activity.activity.id]) {
				totals[activity.activity.id].total += activity.hours;
			} else {
				totals[activity.activity.id] = {
					id: activity.activity.id,
					name: activity.activity.name,
					total: activity.hours,
				};
			}
			allHours += activity.hours;
			return totals;
		},
		{},
	);
	return { totals, allHours };
};

import { Box, Button, Typography } from '@mui/material';
import { endOfDay, startOfDay } from 'date-fns';
import {
	CustomHeadLabelRenderOptions,
	MUIDataTableColumnDef,
	MUIDataTableOptions,
} from 'mui-datatables';
import { useEffect, useState } from 'react';
import { WorkerAttendance } from '../../constants/Attendance';
import { UserDetails } from '../../constants/Common';
import { FirebaseApi } from '../../firebase/firebaseApi';
import { DataTable } from '../DataTable/DataTable';
import { formatDayOfMonth } from '../helpers/dateFormatters';
import { sortByField } from '../helpers/sortHelpers';
import { LoadingDots } from '../Management/subcomponents/LoadingDots';
import { AttendanceCard } from './AttendanceCard';
import { ClearAbsenteesModal } from './ClearAbsenteesModal';
import { RemoveFrom } from './RemoveFrom';

type WorkersAttendance = {
	onTime: WorkerAttendance[];
	late: WorkerAttendance[];
	absent: WorkerAttendance[];
};

export type AttendanceProps = {
	userDetails: UserDetails;
	workerUsersSubscription: (
		callback: (workers: Record<string, UserDetails>) => Promise<void>,
	) => void;
	removedFrom: RemoveFrom;
	removeWorker: (workerID: string) => Promise<void>;
	firebaseApi: Pick<FirebaseApi, 'getFirstWorkerSiteSignInSiteLogForPeriod'>;
};

export const Attendance = ({
	userDetails,
	workerUsersSubscription,
	removedFrom,
	removeWorker,
	firebaseApi,
}: AttendanceProps): JSX.Element => {
	const [loading, setLoading] = useState<boolean>(true);
	const [clearAbsenteesOpen, setClearAbsenteesOpen] =
		useState<boolean>(false);
	const [workersAttendance, setWorkersAttendance] =
		useState<WorkersAttendance>({
			onTime: [],
			late: [],
			absent: [],
		});
	const [search, setSearch] = useState<string>('');
	const [filteredWorkersAttendance, setFilteredWorkersAttendance] =
		useState<WorkersAttendance>({
			onTime: [],
			late: [],
			absent: [],
		});

	useEffect(() => {
		const todayStart = startOfDay(new Date());
		const todayEnd = endOfDay(new Date());
		return workerUsersSubscription(async (workerUsers) => {
			const attendance: WorkersAttendance = {
				onTime: [],
				late: [],
				absent: [],
			};
			await Promise.all(
				Object.values(workerUsers).map(async (user) => {
					const workerAttendance: WorkerAttendance = user;
					const log =
						await firebaseApi.getFirstWorkerSiteSignInSiteLogForPeriod(
							user.userID,
							user.siteID,
							todayStart,
							todayEnd,
						);

					if (log !== null) {
						workerAttendance.datetime = log.datetime;
						const logStartTime =
							log.datetime.toDate().getTime() -
							startOfDay(new Date()).getTime();
						if (logStartTime <= log.siteStartTime) {
							attendance.onTime.push(workerAttendance);
						} else {
							attendance.late.push(workerAttendance);
						}
					} else {
						attendance.absent.push(workerAttendance);
					}
				}),
			);
			// with promise.all we now need to do this post log fetch
			const { onTime, late, absent } = attendance;
			attendance.onTime = sortByField(onTime, 'displayName');
			attendance.late = sortByField(late, 'displayName');
			attendance.absent = sortByField(absent, 'displayName');

			setWorkersAttendance(attendance);
			setLoading(false);
		});
	}, [firebaseApi, userDetails.companyID, workerUsersSubscription]);

	useEffect(() => {
		const searchPredicate = (worker: WorkerAttendance): boolean =>
			worker.displayName.toLocaleLowerCase().includes(search);

		const onTime = workersAttendance.onTime.filter(searchPredicate);
		const late = workersAttendance.late.filter(searchPredicate);
		const absent = workersAttendance.absent.filter(searchPredicate);
		setFilteredWorkersAttendance({ onTime, late, absent });
	}, [search, workersAttendance]);

	const toTableData = (
		workers: WorkersAttendance,
	): (WorkerAttendance | undefined)[][] => {
		const length = Math.max(
			workers.onTime.length,
			workers.late.length,
			workers.absent.length,
		);
		const tableData: (WorkerAttendance | undefined)[][] = [];
		for (let i = 0; i < length; i++) {
			tableData.push([
				workers.onTime[i],
				workers.late[i],
				workers.absent[i],
			]);
		}
		return tableData;
	};

	const tableOptions: MUIDataTableOptions = {
		download: false,
		filter: false,
		print: false,
		search: true,
		sort: false,
		responsive: 'standard',
		rowHover: false,
		selectableRows: 'none',
		isRowSelectable: () => false,
		tableBodyHeight: 'calc(100vh - 304px)',
		onSearchChange: (searchText: string | null) =>
			setSearch(
				searchText !== null ? searchText.toLocaleLowerCase() : '',
			),
		onSearchClose: () => setSearch(''),
		customSearch: () => true, // Cause they try do fancy things here and we don't want it
		customToolbar: () =>
			removedFrom !== RemoveFrom.None && (
				<Button
					variant="contained"
					sx={{ marginLeft: '8px' }}
					disabled={loading || workersAttendance.absent.length === 0}
					onClick={(): void => setClearAbsenteesOpen(true)}>
					Clear Absentees
				</Button>
			),
		textLabels: {
			body: {
				noMatch: loading ? (
					<LoadingDots />
				) : (
					'Sorry, no matching records found'
				),
			},
		},
	};

	const attendanceCountStyle = {
		fontWeight: 'bold',
		fontSize: '3rem',
	};

	const customHeadLabelRender = (
		attendanceCount: number,
		countColor: string,
	): ((options: CustomHeadLabelRenderOptions) => JSX.Element) => {
		const customHeaderLabel = (
			options: CustomHeadLabelRenderOptions,
		): JSX.Element => {
			return (
				<Box data-testid={`${options.label}-count-label`}>
					<Typography
						textAlign="center"
						color={countColor}
						{...attendanceCountStyle}>
						{attendanceCount}
					</Typography>
					<Typography textAlign="center">{options.label}</Typography>
				</Box>
			);
		};
		return customHeaderLabel;
	};

	const columns: MUIDataTableColumnDef[] = [
		{
			name: 'onTime',
			label: 'On Time',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: `${100 / 3}%`,
					},
				}),
				customHeadLabelRender: customHeadLabelRender(
					workersAttendance.onTime.length,
					'success.main',
				),
				customBodyRender: (cellData) =>
					cellData ? <AttendanceCard employee={cellData} /> : <></>,
			},
		},
		{
			name: 'late',
			label: 'Late',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: `${100 / 3}%`,
					},
				}),
				customHeadLabelRender: customHeadLabelRender(
					workersAttendance.late.length,
					'warning.main',
				),
				customBodyRender: (cellData) =>
					cellData ? <AttendanceCard employee={cellData} /> : <></>,
			},
		},
		{
			name: 'absent',
			label: 'Absent',
			options: {
				setCellHeaderProps: () => ({
					style: {
						width: `${100 / 3}%`,
					},
				}),
				customHeadLabelRender: customHeadLabelRender(
					workersAttendance.absent.length,
					'error.main',
				),
				customBodyRender: (cellData) =>
					cellData ? <AttendanceCard employee={cellData} /> : <></>,
			},
		},
	];
	return (
		<>
			<DataTable
				title={`Attendance for ${formatDayOfMonth(new Date())}`}
				tableData={toTableData(filteredWorkersAttendance)}
				columns={columns}
				customTableOptions={tableOptions}
			/>
			{removedFrom !== RemoveFrom.None && (
				<ClearAbsenteesModal
					absentWorkers={workersAttendance.absent}
					modalOpen={clearAbsenteesOpen}
					toggleOpen={(): void =>
						setClearAbsenteesOpen(!clearAbsenteesOpen)
					}
					removedFrom={removedFrom}
					removeWorkers={async (
						workers: WorkerAttendance[],
					): Promise<void[]> =>
						await Promise.all(
							workers.map((worker) =>
								removeWorker(worker.userID),
							),
						)
					}
				/>
			)}
		</>
	);
};
